<template>
  <div>
    <mobile-header></mobile-header>
    <div class="about-container">
      <div class="banner-img">
        <img class="img-tab" src="@A/images/product-banner.webp" alt="">
      </div>
      <div class="about-title">美颜证件照相机</div>

      <div class="about-desc">
        美颜证件照相机是一款智能拍摄证件照的APP，一键换底色、高清导出，一寸照、二寸照、求职照多种功能统统满足！
      </div>

      <div class="about-h2">
        产品特色多样
      </div>

      <div class="about-desc">
        1、AI算法照片处理能力，一键美颜替换底色，证件照生成
        您只需选中所需保留的照片，剩下的部分即可被替换成纯色背景，证件照就生成了。
      </div>

      <div class="about-desc">
        2、 一键美颜
        强大的照片编辑能力，将您的人脸美颜，在制作证件照的同时，也不忘美美哒！
      </div>

      <div class="about-desc">
        3、覆盖多种尺寸
        一寸照、四六级、教师资格证、入学照、考证照、工作照等各种规格，免费、简单、快速、智能生成证件照，满足您的各类证件照需求。如：教资、计算机、四六级、普通话、入学照等学生证照；会计、公务员、医护、考研、幼师及语言类考试证照；求职、入职、简历、签字等标准证件照、电子版证件照等各类拍摄场景需求。
      </div>
      <div class="footer">
        <div class="line"></div>
        <p>联系方式：4008939808 公司地址：广州市海珠区新港东路1088号1110室（部位：自编之四）</p>
        <p>粤ICP备2021025534号 | 广州市云兰霞衣科技有限公司</p>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Product',
  data() {
    return {

    }
  },
  components: {
  },
  mounted() {

  },
  methods: {

  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.about-container {
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .banner-img {
    .img-tab {
      width: 100vw;
      height: 236px;
    }
  }
  .about-title {
    width: 379px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 27px;
    color: #111111;
    line-height: 38px;
    text-align: right;
    font-style: normal;
    margin-top: 48px;
  }
  .about-desc {
    width: 675px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #111111;
    line-height: 27px;
    text-align: left;
    font-style: normal;
    margin-top: 26px;
  }

  .about-h2 {
    width: 675px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #111111;
    line-height: 27px;
    text-align: left;
    font-style: normal;
    margin-top: 30px;
  }
  .footer {
    margin-top: 120px;
    padding-bottom: 50px;
    .line {
      width: 675px;
      height: 1px;
      background: #e5e5e5;
      margin-bottom: 30px;
    }
    p {
      width: 100%;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 7px;
      color: #808080;
      line-height: 10px;
      text-align: left;
      font-style: normal;
      margin-bottom: 12px;
      text-align: center;
    }
  }
}
</style>