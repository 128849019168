<template>
  <div>
    <mobile-header></mobile-header>
    <div class="about-container">
      <div class="banner-img">
        <img class="img-tab" src="@A/images/about-banner.webp" alt="">
      </div>
      <div class="about-title">广州市云兰霞衣科技有限公司</div>
      <div class="about-desc">
        广州市云兰霞衣科技有限公司是一家专注为全世界用户提供数据恢复解决方案的中国企业，公司总部坐落于中国硅谷——广州，在香港等地分别设有分公司。作为一家拥有自主研发实力的高新技术企业，广州市云兰霞衣科技有限公司多年来一直致力于“数据恢复的技术创新，为用户数据保驾护航”。
        依托互联网和大数据优势，以及持续投入和对用户需求的深入挖掘，于2017年3月成立数据兔恢复大师品牌，截止目前为止已为全球3千多万的用户提供了强大的数据支持。是全球领先的新生代数据服务赋能者，也是数据恢复领域最具影响力的数据恢复品牌之一。
      </div>
      <div class="footer">
        <div class="line"></div>
        <p>联系方式：4008939808 公司地址：广州市海珠区新港东路1088号1110室（部位：自编之四）</p>
        <p>粤ICP备2021025534号 | 广州市云兰霞衣科技有限公司</p>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'About',
  data() {
    return {

    }
  },
  components: {
  },
  mounted() {

  },
  methods: {

  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.about-container {
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .banner-img {
    .img-tab {
      width: 100vw;
      height: 236px;
    }
  }
  .about-title {
    width: 379px;
    height: 38px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 27px;
    color: #111111;
    line-height: 38px;
    text-align: right;
    font-style: normal;
    margin-top: 48px;
  }
  .about-desc {
    width: 675px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #111111;
    line-height: 27px;
    text-align: left;
    font-style: normal;
    margin-top: 26px;
  }

  .footer {
    margin-top: 120px;
    padding-bottom: 50px;
    .line {
      width: 675px;
      height: 1px;
      background: #e5e5e5;
      margin-bottom: 30px;
    }
    p {
      width: 100%;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 7px;
      color: #808080;
      line-height: 10px;
      text-align: left;
      font-style: normal;
      margin-bottom: 12px;
      text-align: center;
    }
  }
}
</style>